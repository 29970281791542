<template>
  <div>
    <ProfileListAddv2
      :createProfileActive.sync="createProfileActive"
      :selectedStudent="studentData"
      :redirectOnComplete="false"
      @refetch-data="refetchData"
      v-if="$can('roster.profile.create')"
    />

    <b-card no-body class="mb-0 table-no-top table-text-nowrap">
      <b-table
        ref="refProfileListTable"
        class="position-relative mb-25"
        :items="fetchProfiles"
        responsive
        :fields="tableColumnsStudentView"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No profiles assigned to this user"
        :sort-desc.sync="isSortDirDesc"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Session -->
        <template #cell(session)="data">
          <div class="d-flex align-items-center">
            <div @click="data.toggleDetails" style="margin-left: -5px" class="mr-50 cursor-pointer" v-if="data.item.session">
              <feather-icon icon="ChevronDownIcon" size="24" v-if="data.detailsShowing" />
              <feather-icon icon="ChevronRightIcon" size="24" v-if="!data.detailsShowing" />
            </div>
            <div class="text-nowrap" v-if="data.item.session">
              <div
                v-if="data.item.session.type === 'pathway' && data.item.session.pathway"
                class="d-flex align-items-center text-nowrap"
                style="gap: 0.625rem"
              >
                <b-avatar
                  size="36"
                  :src="data.item.session.pathway.avatar"
                  :text="avatarText(data.item.session.pathway.name)"
                  :variant="`light-info`"
                  :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.session.id } }"
                  style="margin-top: 2px"
                  rounded
                />
                <div class="d-flex flex-column" style="gap: 0">
                  <div class="d-flex align-items-center font-weight-bold text-nowrap text-white mb-0">
                    <div
                      class="cursor-pointer"
                      @click="$router.push({ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.session.id } })"
                    >
                      {{ resolvePathwayCohortName(data.value) }}
                    </div>
                    <div v-if="data.item.reassignFrom" class="ml-50">
                      <feather-icon
                        @click="redirectToReassigned(data.item.reassignFrom)"
                        icon="InfoIcon"
                        class="text-warning cursor-pointer"
                        :id="`sr-${data.item.id}-reassign-${data.item.reassignFrom.id}-tooltip`"
                        style="margin-bottom: 2px; z-index: 9"
                      />
                      <b-tooltip
                        placement="right"
                        @click="redirectToReassigned(data.item.reassignFrom)"
                        :title="`Reassigned From ${data.item.reassignFrom.name}`"
                        class="cursor-pointer"
                        :target="`sr-${data.item.id}-reassign-${data.item.reassignFrom.id}-tooltip`"
                      />
                    </div>
                  </div>
                  <b-link
                    :to="{ name: 'apps-roster-pathway-cohort-view', params: { id: data.item.session.id } }"
                    class="d-flex align-items-center text-muted text-nowrap"
                  >
                    <lucide-icon name="Library" style="width: 14px; height: 14px; margin-right: 1px" />
                    <small> {{ data.item.session.pathway.name }}</small>
                  </b-link>
                </div>
              </div>
              <div
                v-if="data.item.session.type === 'course' && data.item.session.course"
                class="d-flex align-items-center text-nowrap"
                style="gap: 0.625rem"
              >
                <b-avatar
                  size="36"
                  :src="data.item.session.course.avatar"
                  :text="avatarText(data.item.session.course.name)"
                  :variant="`light-info`"
                  :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.session.id } }"
                  style="margin-top: 2px"
                  rounded
                />
                <div class="d-flex flex-column" style="gap: 0">
                  <div class="d-flex align-items-center font-weight-bold text-nowrap text-white mb-0">
                    <div
                      class="cursor-pointer"
                      @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.session.id } })"
                    >
                      {{ resolveCourseSectionName(data.value) }}
                    </div>
                    <div v-if="data.item.reassignFrom" class="ml-50">
                      <feather-icon
                        @click="redirectToReassigned(data.item.reassignFrom)"
                        icon="InfoIcon"
                        class="text-warning cursor-pointer"
                        :id="`sr-${data.item.id}-reassign-${data.item.reassignFrom.id}-tooltip`"
                        style="margin-bottom: 2px; z-index: 9"
                      />
                      <b-tooltip
                        @click="redirectToReassigned(data.item.reassignFrom)"
                        placement="right"
                        :title="`Reassigned From ${data.item.reassignFrom.name}`"
                        class="cursor-pointer"
                        :target="`sr-${data.item.id}-reassign-${data.item.reassignFrom.id}-tooltip`"
                      />
                    </div>
                  </div>
                  <b-link
                    :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.session.id } }"
                    class="d-flex align-items-center text-muted text-nowrap"
                  >
                    <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                    <small> {{ data.item.session.course.name }}</small>
                  </b-link>
                </div>
              </div>
            </div>
            <div class="text-nowrap d-flex align-items-center" v-else>
              <feather-icon
                :id="`profile-row-${data.item.id}-set-session-icon`"
                icon="PlusCircleIcon"
                class="cursor-pointer mr-25"
                size="16"
                @click="startSessionSelect(data.item)"
              />

              <b-tooltip title="Assign Session" class="cursor-pointer" :target="`profile-row-${data.item.id}-set-session-icon`" />

              Not Enrolled
            </div>
          </div>
        </template>

        <!-- Column: Session -->
        <template #cell(email)="data">
          <span class="text-nowrap">{{ data.item.student.email }}</span>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(fundingSource)="data">
          <b-badge
            :variant="`light-${resolveProfileProgramVariant(data.item.attributes.fundingSource)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.attributes.fundingSource) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Payment Status -->
        <template #cell(paymentStatus)="data">
          <b-badge
            :variant="`${resolveProfilePaymentStatusVariant(data.item.attributes.paymentStatus)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.attributes.paymentStatus) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Uploaded Resume -->
        <template #cell(uploadedResume)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-resume-icon`"
              icon="UploadIcon"
              class="cursor-pointer"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'resume')"
            />

            <b-tooltip title="Upload Resume" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-resume-icon`" />
            &nbsp;
            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedResume)}`" class="text-capitalize">
              {{ title(data.item.attributes.uploadedResume) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded COE -->
        <template #cell(uploadedCoe)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-coe-icon`"
              icon="UploadIcon"
              class="cursor-pointer"
              size="16"
              @click="startUpload(data.item, 'enrollment', 'coe')"
            />

            <b-tooltip title="Upload COE" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-coe-icon`" />
            &nbsp;
            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedCoe)}`" class="text-capitalize">
              {{ title(data.item.attributes.uploadedCoe) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - Uploaded Transcript -->
        <template #cell(uploadedTranscript)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`profile-row-${data.item.id}-upload-transcript-icon`"
              icon="UploadIcon"
              class="cursor-pointer"
              size="16"
              @click="startUpload(data.item, 'transcript', 'prior')"
            />

            <b-tooltip title="Upload Transcript" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-transcript-icon`" />
            &nbsp;
            <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedTranscript)}`" class="text-capitalize">
              {{ title(data.item.attributes.uploadedTranscript) }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Attributes - VA Submission -->
        <template #cell(submittedToVa)="data">
          <feather-icon
            :id="`profile-row-${data.item.id}-update-submission`"
            icon="CheckCircleIcon"
            class="cursor-pointer"
            size="16"
            @click="markProfileAsSubmitted(data.item)"
            v-if="data.item.attributes.submittedToVa === 'no'"
          />

          <b-tooltip
            title="Mark as Submitted"
            class="cursor-pointer"
            :target="`profile-row-${data.item.id}-update-submission`"
            v-if="data.item.attributes.submittedToVa === 'no'"
          />

          <b-badge :variant="`light-${resolveProfileYNVariant(data.item.attributes.submittedToVa)}`" class="text-capitalize">
            {{ title(data.item.attributes.submittedToVa) }}
          </b-badge>
        </template>

        <!-- Column: Session -->
        <template #cell(createdAt)="data">
          {{ momentTz(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Retake -->
        <template #cell(retake)="data">
          <b-badge :variant="`${resolveProfileYNVariant(data.item.attributes.retake || false)}`" class="text-capitalize">
            {{ title(data.item.attributes.retake || 'No') }}
          </b-badge>
        </template>

        <!-- Column: Modality -->
        <template #cell(modality)="data">
          <b-badge :variant="`${resolveProfileLocationStatus(data.item.attributes.location)}`" class="text-capitalize">
            {{ title(data.item.attributes.location) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveProfileStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Transfer of Credit -->
        <template #cell(toc)="data">
          <b-badge :variant="`light-${resolveProfileYNVariant(data.item.toc.enabled ? 'yes' : 'no')}`" class="text-capitalize">
            {{ title(data.item.toc.enabled ? 'Yes' : 'No') }}
          </b-badge>
        </template>

        <!-- Column: Compliance Score -->
        <template #cell(compliance)="data">
          <template v-if="!data.item.compliance || !data.item.compliance.scores || !data.item.session">
            <b-badge variant="danger">N/A</b-badge>
          </template>

          <template v-else>
            <b-badge
              :variant="`${resolveComplianceScoreVariant({
                score: data.item.compliance.scores.overall,
              })}`"
              :id="`compliance-${data.item.id}`"
              class="text-capitalize"
              style="cursor: help"
            >
              {{ gradeScore({ score: data.item.compliance.scores.overall, percent: true, status: data.item.status }) }}
              <font-awesome-icon v-if="data.item.compliance.locked" icon="fa-regular fa-lock" />
            </b-badge>

            <compliance-popover
              :target-id="data.item.id"
              :student-name="data.item.student.firstName"
              :compliance-data="data.item.compliance"
              :sections-data="data.item.session.sections"
            />
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item
              v-if="$can('roster.profile.update')"
              @click="$router.push({ name: 'apps-roster-profile-edit', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item @click="focusProfileDocuments(data.item)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Focus - Documents</span>
                <feather-icon icon="FilePlusIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <template v-if="data.item.session">
              <b-dropdown-divider />

              <b-dropdown-item @click="downloadAttendanceSheet(data.item.session, data.item)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span>Attendance</span>
                  <feather-icon icon="BookOpenIcon" size="15" class="ml-50 text-muted" />
                </div>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('roster.cohort.student.credit')" @click="openCourseToCModal(data.item, true)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span>Add Credit</span>
                  <feather-icon icon="CreditCardIcon" size="15" class="ml-50 text-muted" />
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.toc.enabled && $can('roster.cohort.student.credit')"
                @click="openCourseToCModal(data.item, false)"
              >
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span>Remove Credit</span>
                  <feather-icon icon="CreditCardIcon" size="15" class="ml-50 text-muted" />
                </div>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('roster.cohort.student.remove')" @click="removeFromCohort(data.item.session.id, data.item.id)">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span>Remove</span>
                  <feather-icon icon="ArchiveIcon" size="15" class="ml-50 text-muted" />
                </div>
              </b-dropdown-item>
            </template>

            <b-dropdown-divider />

            <b-dropdown-item v-if="$can('roster.profile.archive')" @click="archiveProfile(data.item.id)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Archive</span>
                <feather-icon icon="ArchiveIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!--- Sections for Profile -->
        <template #row-details="row">
          <student-relations-roster-profile-section-list
            :student-id="row.item.student.id"
            :cohort-id="row.item.session.id"
            :profile-id="row.item.id"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-75 mt-0">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                size="s"
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProfiles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <vue-context ref="studentRosterProfileMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          v-if="$can('roster.profile.update')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-profile-edit', params: { id: data.item.id } })"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a class="d-flex align-items-center justify-content-between" @click="() => focusProfileDocuments(data.item)">
          <span>Focus - Documents</span>
          <feather-icon icon="FilePlusIcon" size="15" class="ml-50 text-muted" />
        </a>

        <a
          v-if="
            data.item.metadata.enrollmentToc &&
            data.item.metadata.enrollmentToc.schools &&
            data.item.metadata.enrollmentToc.schools.length > 0
          "
          class="d-flex align-items-center justify-content-between"
          @click="openCourseToCViewModal(data.item)"
        >
          <span>View Credit</span>
          <feather-icon icon="CreditCardIcon" class="ml-50 text-muted" />
        </a>

        <template v-if="data.item.session">
          <a
            v-if="$can('roster.cohort.student.credit')"
            class="d-flex align-items-center justify-content-between"
            @click="openCourseToCModal(data.item, true)"
          >
            <span class="align-middle">Add Credit</span>
            <feather-icon icon="CreditCardIcon" />
          </a>
          <a
            class="d-flex align-items-center justify-content-between"
            v-if="data.item.toc.enabled && $can('roster.cohort.student.credit')"
            @click="openCourseToCModal(data.item, false)"
          >
            <span class="align-middle">Remove Credit</span>
            <feather-icon icon="CreditCardIcon" />
          </a>
        </template>

        <a
          v-if="$can('roster.profile.delete')"
          class="d-flex align-items-center justify-content-between"
          @click="archiveProfile(data.item.id)"
        >
          <span>Archive</span>
          <feather-icon icon="ArchiveIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>

    <student-view-credit
      v-if="isViewCreditOpen"
      :is-view-credit-open.sync="isViewCreditOpen"
      :profile="selectedProfile"
      :student="studentData"
    ></student-view-credit>
    <student-add-credit
      v-if="isAddCreditOpen"
      v-on:refresh="refetchData"
      :is-add-credit-open.sync="isAddCreditOpen"
      :selected-profile="selectedProfile"
      :cohort-data="selectedCohort"
      :is-assign="isAssignOp"
    ></student-add-credit>
    <profile-add-to-session :is-add-session-open.sync="assignProfileCohortActive" v-on:refresh="refetchData" :profile="cohortProfile" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref } from '@vue/composition-api';
import fileDownload from 'js-file-download';
import VueContext from 'vue-context';

import store from '@/store';
import { avatarText, title, gradeScore } from '@core/utils/filter';
import { momentTz } from '@/libs/utils/time';

import FormattedTime from '@/@core/components/time/FormattedTime.vue';
import useProfiles from '@/views/apps/roster/profile/useProfiles';
import useUsersList from '@/views/apps/audit/audit-list/useAuditList';
import usePathways from '@/views/apps/roster/pathways/usePathways';
import useCourses from '@/views/apps/roster/courses/useCourses';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import StudentRelationsRosterProfileSectionList from './StudentRelationsRosterProfileSectionList.vue';
import ProfileAddToSession from '@/views/apps/roster/profile/list/components/ProfileAddToSession.vue';
import ProfileListAddv2 from '@/views/apps/roster/profile/create/ProfileListAddv2.vue';
import StudentAddCredit from '@/views/apps/student/students-view/components/StudentAddCredit.vue';
import StudentViewCredit from '@/views/apps/student/students-view/components/StudentViewCredit.vue';
import CompliancePopover from '@/views/apps/roster/courses/sections/view/components/CompliancePopover.vue';

export default {
  components: {
    CompliancePopover,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BTooltip,

    FormattedTime,

    vSelect,
    VueContext,

    StudentRelationsRosterProfileSectionList,
    ProfileAddToSession,
    ProfileListAddv2,
    StudentAddCredit,
    StudentViewCredit,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    redirectToReassigned(cohort) {
      if (cohort.type === 'course' && Array.isArray(cohort.sections) && cohort.sections.length > 0) {
        const id = cohort.sections[0].id ? cohort.sections[0].id : cohort.sections[0];
        return this.$router.push(`/roster/courses/sections/${id}`);
      }
      if (cohort.type === 'pathway') {
        const id = cohort.id ? cohort.id : cohort._id;
        return this.$router.push(`/roster/pathways/cohorts/${id}`);
      }
    },
    createNewProfile() {
      this.createProfileActive = true;
    },
    startSessionSelect(profile) {
      this.cohortProfile = profile;
      this.assignProfileCohortActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.studentRosterProfileMenu.open(event, { item });
      event.preventDefault();
    },
    focusProfileDocuments(profile) {
      this.$emit('focus-document', { profileId: profile.id });
    },
    async removeFromCohort(cohortId, profileId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to remove this student from this cohort?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.$store
            .dispatch('rosterStoreModule/removeStudentsFromPathwayCohort', {
              cohortId,
              profileIds: [profileId],
            })
            .then(() => {
              this.refetchData();
            })
            .catch((error) => {
              console.error('failed to remove students to class', error);
            });
        }
      });
    },
    archiveProfile(profileId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this profile?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/updateProfile', {
              id: profileId,
              profileData: {
                status: 'archived',
              },
            })
            .then((response) => {
              this.refetchData();
            });
        }
      });
    },
    openCourseToCViewModal(profile) {
      this.selectedProfile = profile;
      this.$nextTick(() => (this.isViewCreditOpen = true));
    },
    openCourseToCModal(profile, isAssign) {
      if (!profile.session) {
        this.$toast('Must assign to a cohort before attempting to assign credit');
        return;
      }

      this.selectedProfile = profile;
      this.selectedCohort = profile.session;
      this.isAssignOp = isAssign;
      this.$nextTick(() => (this.isAddCreditOpen = true));
    },

    async downloadAttendanceSheet(cohort, profile) {
      this.isBtnBusy = true;

      this.$store
        .dispatch('rosterStoreModule/exportProfileAttendance', {
          id: profile.id,
          queryParams: { mode: 'standard' },
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          fileDownload(data, `${cohort.name} Attendance (${this.studentData.fullName}).xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
        });
    },
  },
  setup(props) {
    const createProfileActive = ref(false);
    const assignProfileCohortActive = ref(false);
    const cohortProfile = ref(null);

    const { resolveUserRoleVariant } = useUsersList();
    const { resolvePathwayStatusVariant } = usePathways();
    const { resolveCourseStatusVariant } = useCourses();

    const { resolvePathwayCohortName } = usePathwayCohorts();
    const { resolveCourseSectionName } = useCourseSections();

    const { resolveComplianceScoreVariant } = useProfiles();

    const isViewCreditOpen = ref(false);
    const isAddCreditOpen = ref(false);
    const isAssignOp = ref(true);
    const selectedProfile = ref(null);
    const selectedCohort = ref(null);

    const {
      fetchProfiles,
      tableColumnsStudentView,
      perPage,
      currentPage,
      totalProfiles,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProfileListTable,
      refetchData,

      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,
      resolveProfileLocationStatus,

      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,

      studentFilter,
    } = useProfiles();

    studentFilter.value = props.studentData.id;

    return {
      fetchProfiles,
      tableColumnsStudentView,
      perPage,
      currentPage,
      totalPageCount,
      totalProfiles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProfileListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      resolveUserRoleVariant,

      resolveProfileVisibilityVariant,
      resolveProfileStatusVariant,
      resolveProfileLocationStatus,

      resolveProfilePaymentStatusVariant,
      resolveProfileProgramVariant,
      resolveProfileYNVariant,

      resolvePathwayStatusVariant,
      resolveCourseStatusVariant,

      resolveComplianceScoreVariant,
      gradeScore,

      studentFilter,

      resolvePathwayCohortName,
      resolveCourseSectionName,

      createProfileActive,
      assignProfileCohortActive,
      cohortProfile,

      isViewCreditOpen,
      isAddCreditOpen,
      isAssignOp,
      selectedProfile,
      selectedCohort,

      momentTz,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
